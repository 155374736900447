export class User {

    firstName: string;
    lastName: string;

    email: string;
    password: string;

    token: string;
    phone: string;

    get fullName(): string {
        return `${this.firstName} ${this.lastName}`;
    }

}
