import { Injectable } from '@angular/core';
import { User } from './user.class';
import { HttpClient } from '@angular/common/http';
import { Register } from './register.class';
import { Login } from './login.class';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResetPassword } from './reset-password.class';
import { ConfigService } from 'saturn-angular-lib';


export const AUTH_LOCAL_STORAGE_KEY = 'currentUser';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    baseUrl: string;

    constructor(public http: HttpClient, private config: ConfigService) {
        this.baseUrl = config.apiFullUrl;
    }


    getUser(): User {
        const jsonUser = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
        return JSON.parse(jsonUser) as User;
    }


    getAuthToken() {
        const jsonUser = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
        return jsonUser ? JSON.parse(jsonUser).token : '';
    }

    forgotPassword(email: string): Observable<string> {

        return this.http.post<{ status }>(this.baseUrl + '/password/forgot', {
            email: email,
        }).pipe(map((response) => {
            return response.status;
        }));
    }

    resetPassword(form: ResetPassword): Observable<any> {
        return this.http.post<string>(this.baseUrl + '/password/reset', form).pipe(map((response) => {
            return response;
        }));
    }


    login(loginModel: Login): Observable<User> {

        loginModel.client_id = this.config.clientId;
        loginModel.client_secret = this.config.clientSecret;
        loginModel.grant_type = 'password';

        return this.http.post<any>(this.config.apiUrl + '/oauth/token', loginModel).pipe(map((response) => {

            if (response && response.access_token) {

                const user = new User();
                user.token = response.access_token;
                user.email = loginModel.username;

                localStorage.setItem('currentUser', JSON.stringify(user));

                return user;
            }
            return null;

        }));
    }

    register(registerModel: Register): Observable<User> {

        return this.http.post<string>(this.baseUrl + '/register', registerModel).pipe(map((token) => {
            const user = new User();

            if (token) {
                user.firstName = registerModel.firstName;
                user.lastName = registerModel.lastName;
                user.email = registerModel.email;
                user.phone = registerModel.phone;
                user.token = token;

                localStorage.setItem('currentUser', JSON.stringify(user));

            }
            return user;
        }));
    }


    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
    }


}
